import api from "@/api";
import { DefaultSetting } from "@/models/type";
import { Vue } from "vue-class-component";
import Label from "../home/components/Label";
import './index.scss'
import bg1 from './assets/1.jpg'
import bg2 from './assets/bg_1.png'
export default class About extends Vue {

  public list: Array<SysPropertiesItemList> = []
  public teamList: Array<SysTeam> = []
  public institutionList: Array<SysInstitutionNew> = []
  public cooperationList: Array<SysCooperation> = []
  type = 1

  getData(): void {
    Promise.all([
      api.getSpaceIntroduce(),
      api.getTeamList(),
      api.getInstitutionList(),
      api.getCooperationList()
    ]).then(res => {
      this.list = res[0].data
      this.teamList = res[1].data
      this.institutionList = res[2].data
      this.cooperationList = res[3].data
    })
  }

  selectDom(): JSX.Element {
    return <div class="about-select">
      <div onClick={() => this.type = 1} class={this.type === 1 ? 'active': ''}>团队成员</div>
      <span />
      <div onClick={() => this.type = 2} class={this.type === 2 ? 'active': ''}>合作机构</div>
      {/* <span /> */}
      {/* <div onClick={() => this.type = 3} class={this.type === 3 ? 'active': ''}>友好连结</div> */}
    </div>
  }

  cellDom(): JSX.Element {
    switch (this.type) {
      case 1:
        return <div class="team">{
          this.teamList.map(item => <div class="team-list">
            <div class="team-left">
              <div/>
              <img src={item.imgPath} alt="" />
            </div>
            <div class="team-right">
              <div class="name">{item.cnName}（{item.enName}）</div>
              <div class="identity">{item.identity}</div>
              <div class="team-right-bottom">
                <div class="motto">{item.motto}</div>
                <div class="bottom">
                  <i class="el-icon-message"/>
                  <span>{item.email}</span>
                </div>
              </div>
            </div>
          </div>)
        }</div>
      case 2:
        return <div class="about-institution">
          {this.institutionList.map(item => (
            <div class="institution-cell">
              <div class="institution-cell-title">{item.type}</div>
              {item.sysInstitutions?.map(item => <div class="institution-cell-list">
                <div class="institution-cell-block">
                  <img src={item.icon} alt="" />
                  <div class="institution-cell-right">
                    <div>{item.title}</div>
                    <div>{item.introduce}</div>
                    <div class="pointer" onClick={() => window.open(item.url)}>{item.url}</div>
                  </div>
                </div>
              </div>)}
            </div>
          ))}
        </div>
      case 3:
        return <div class="about-cooperation">
          {this.cooperationList.map(item => <div>{ item.title }</div>)}
        </div>
      default:
        return <div/>
    }
  }

  showDom(): JSX.Element {
    return <div class="about-main">
      <el-row>
        <el-col xs={12} sm={12} md={12} lg={12} xl={12}>
          <img style={{height: '500px', objectFit: 'cover'}} src={bg1} alt="" />
        </el-col>
        <el-col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div class="about-main-right">
            <div>香港青少年文創空間--道驛空間項目致力傳承和發展中華優秀文化藝術，有效起到服務青少年群體的作用。空間舉辦了多個與中華文化有關的課程，涵蓋琴棋書畫以及傳統技藝。空間會舉辦加入創新元素既工作坊及活動，將新科技與中華文化結合，從而傳播正能量，吸引和鼓勵更多青年人創業，走進大中華，展開社會服務工作。空間透過舉辦各類活動加深新一代對中華文化的認識，讓他們成為香港未來長期在中華文化上穩定發展的關鍵，共同推廣、發揚、承傳中華文化。</div>
            <div class="about-main-right-fly">
              <div>香港青少年文創空間</div>
              <div>道驛空間</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="about-main-l2">
        <el-col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div></div>
          {/* <div class="about-main-left">香港青少年文创空间--道驿空间项目致力传承和发展中华优秀文化艺术，道驿空间作为一个文化艺术工作者的驿站，希望透过知识的传播和社会教化起到“溯本清源，直指人心”，有效服务青少年群体的作用；成为培养热爱文化和艺术的青少年平台，引领大家由空间出发，察看祖国之大，湾区之无限可能，同时发掘属于自己的一片新天地。</div> */}
        </el-col>
        <el-col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div class="about-main-right2">
            {/* <img src={bg1} alt="" />
            <div class="about-main-right2-fly">
              <div>Taosseract</div>
            </div> */}
          </div>
        </el-col>
      </el-row>
    </div>
  }


  render(): JSX.Element {
    return <div class="about">
      <Label title={{ zh: '香港青少年文創空間——道驛空間', cn: '' }} />
      {this.showDom()}
      {/* {this.selectDom()} */}
      {/* {this.cellDom()} */}
    </div>
  }
  mounted(): void {
    this.getData()
  }
}

/**
 * @description 空间介绍
 * @author Jinx
 * @date 2021-08-20 09:51:39
 * @export
 * @interface SysPropertiesItemList
 * @extends {DefaultSetting}
 */
 export interface SysPropertiesItemList extends DefaultSetting {
  paramKey?: string // key
  paramValue?: string // key
  propertiesCode?: string // 属性代码
  remark?: string // 备注
  status?: number // 状态 0不启用 1启用
  title?: string
  type?: string // 类型
}


/**
 * @description 团队成员
 * @author Jinx
 * @date 2021-08-20 12:09:30
 * @export
 * @interface SysTeam
 * @extends {DefaultSetting}
 */
export interface SysTeam extends DefaultSetting {
  cnName?: string // 中文名
  email?: string // 邮箱
  enName?: string // 英文名
  identity?: string // 身份
  imgPath?: string // 图片路径
  motto?: string // 格言
}

/**
 * @description 合作机构
 * @author Jinx
 * @date 2021-08-20 14:09:18
 * @export
 * @interface SysInstitution
 * @extends {DefaultSetting}
 */
export interface SysInstitution extends DefaultSetting {
  introduce?: string // 简介
  icon?: string // 图标
  title?: string // 名称
  type?: string // 属性
  url?: string // 链接
}

export interface SysInstitutionNew {
  type: string,
  sysInstitutions: Array<SysInstitution>
}


/**
 * @description 友好连接
 * @author Jinx
 * @date 2021-08-20 14:47:33
 * @export
 * @interface SysCooperation
 * @extends {DefaultSetting}
 */
export interface SysCooperation	extends DefaultSetting {
  sort?: number // 排序
  title?: number // 名称
}
